<template>
  <article class="content row">

    <section class="col-lg-8 col-sm-12">
      <div
        style="background: linear-gradient(#1d1d1d, black); min-height: 600px"
      >
        <DynamicCard :slug="this.slug" short></DynamicCard>
      </div>
      <div class="d-none d-xl-inline">
        <h4 class="section-header text-smart">
          <img
            v-lazy="tulumPartyGreen + '?h=40&w=40'"
            height="40px"
            width="40px"
            style="border-radius: 300px"
          />
          MORE
        </h4>
        <DynamicLinks
          :links="links.slice(3, 6)"
          size="sm"
          style="background: linear-gradient(mediumaquamarine, black)"
        >
          >
        </DynamicLinks>
      </div>
    </section>

    <aside class="card col-4 col-lg-4 d-none d-lg-inline">
      <section
        v-if="links.length"
        class=" "
        style="background: linear-gradient(#1f322f, black)"
      >
        <img
          v-lazy="this.djImage"
          alt="A DJ presenting  links & resources"
          height="100px"
          class="pull-right"
        />
        <h4 class="section-header text-left">
          <img
            v-lazy="tulumPartyGreen + '?h=40&w=40'"
            height="40px"
            width="40px"
            style="border-radius: 300px"
          />
          RECENT
        </h4>
        <br />

        <DynamicLinks
          :links="links.slice(0, 5)"
          class="d-xl-none d-md-inline"
          size="lg"
          style="background: linear-gradient(#1f322f, black)"
        >
        </DynamicLinks>

        <DynamicLinks
          :links="links.slice(0, 5)"
          class="d-none d-xl-inline"
          size="sm"
          style="background: linear-gradient(mediumaquamarine, black)"
        >
          >
        </DynamicLinks>

        <br />
      </section>
    </aside>
  </article>
</template>

<script>
const DynamicCard = () => import('@/components/Cards/DynamicCard');
const DynamicLinks = () => import('@/components/DynamicLinks');

export default {
  name: 'TulumShorts',
  metaInfo() {
    let shortName =
      this.slug.replaceAll('-', ' ').toUpperCase() ||
      'Tulum: All you need to know';
    return {
      title: shortName,
      meta: [
        {
          name: 'description',
          content: `Tulum ${shortName}`,
        },
        {
          name: 'keywords',
          content: this.slug.replaceAll('-', ' '),
        },
      ],
    };
  },
  created: function () {
    this.$store.dispatch('getLinks', { slug: 't' });
  },
  data: () => {
    return {
      tulumPartyGreen:
        'https://imgix.cosmicjs.com/717f22a0-d081-11ee-9ce5-59949019255e-tulum-party-green.jpg',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
    };
  },
  computed: {
    links: {
      get() {
        return this.shuffleArray(this.$store.state.links || []);
      },
    },
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
  },
  props: {
    slug: {
      type: String,
      description: 'Card Slug',
    },
    title: {
      type: String,
      description: 'Card title',
      default: '',
    },
  },
  components: {
    DynamicCard,
    DynamicLinks,
  },
};
</script>
